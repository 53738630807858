import Contract from './Contract.vue'
import axios from 'axios'
import jwt_decode from "jwt-decode";
export default {
    components:{
        Contract
    },
    data(){
        return {
            activeName: 'first',
            verifyMsg: {
                name: '',
                idNo: '',
                account: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                idNo: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' },
                    { pattern:/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证号格式不正确' }
                ],
                account: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    {validator:function(rule,value,callback){
                        if(/(^1|^84)(\d{10}|\d{9})$/.test(value) == false){
                            callback(new Error('请输入正确的手机号'));
                        }else{
                            callback();
                        }
                    }, trigger: 'blur'}
                ],
            },
            verifyShow:true,
            iframeUrl: '',
            iframeShow: false,
            registerMsg: {
                user_name: '',//法人姓名
                user_id_type: 'CRED_PSN_CH_IDCARD',
                user_id_number:'',//身份证号
                user_mobile:'',//13526523222手机号
                user_email: '',//123@qq.com邮箱
                company_name: '',//测试公司名称组织机构名称
                company_id_type: 'CRED_ORG_USCC',//组织机构类型
                company_id_number:'',// 91370888XA3M2PJFX2组织机构证件号 测试:91370888XA3M2PJFX1
            },
            registerRules:{
                user_name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                user_id_number: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' },
                    { pattern:/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证号格式不正确' }
                ],
                user_mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    {validator:function(rule,value,callback){
                        if(/(^1|^84)(\d{10}|\d{9})$/.test(value) == false){
                            callback(new Error('请输入正确的手机号'));
                        }else{
                            callback();
                        }
                    }, trigger: 'blur'}
                ],
                user_email: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱', trigger: 'blur' }
                ],
                company_name: [
                    { required: true, message: '请输入机构名称', trigger: 'blur' },
                ],
                company_id_type: [
                    { required: true, message: '请选择机构类型', trigger: 'change' },
                ],
                company_id_number: [
                    { required: true, message: '请输入机构证件号', trigger: 'blur' },
                ]
            },
            registerShow: false,
            allowRegister: false,
            company_id_type_list:[
                {
                    value: 'CRED_ORG_USCC',
                    label: '统一社会信用代码'
                },
                {
                    value: 'CRED_ORG_CODE',
                    label: '组织机构代码证'
                },
                {
                    value: 'CRED_ORG_REGCODE',
                    label: '工商注册号'
                },
                {
                    value: 'CRED_ORG_BUSINESS_REGISTTATION_CODE',
                    label: '工商登记证'
                },
                {
                    value: 'CRED_ORG_TAX_REGISTTATION_CODE',
                    label: '税务登记证'
                },
                {
                    value: 'CRED_ORG_LEGAL_PERSON_CODE',
                    label: '法人代码证'
                },
                {
                    value: 'CRED_ORG_ENT_LEGAL_PERSON_CODE',
                    label: '事业单位法人证书'
                },
                {
                    value: 'CRED_ORG_SOCIAL_REG_CODE',
                    label: '社会团体登记证书'
                },
                {
                    value: 'CRED_ORG_PRIVATE_NON_ENT_REG_CODE',
                    label: '民办非机构登记证书'
                },
                {
                    value: 'CRED_ORG_FOREIGN_ENT_REG_CODE',
                    label: '外国机构常驻代表机构登记证'
                },
                {
                    value: 'CRED_ORG_GOV_APPROVAL',
                    label: '政府批文'
                },
            ],
            
            isAble: false,
            fileList: [],
            form: {
                title: '123'
            },
            formPopup: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            formLabelWidth: '150px',
            allowChange:false,
            party_b_org_code:'',
            businessScene:'',
            pageA:1,
            per_pageA:10,
            currentPageA:1,
            countA:0,
            contractListA:[],
            pageB:1,
            per_pageB:10,
            currentPageB:1,
            countB:0,
            contractListB:[],
            contractLists:[],
            pageL:1,
            per_pageL:10,
            currentPageL:1,
            countL:0,
            contractListZ:[],
            pageZ:1,
            per_pageZ:10,
            currentPageZ:1,
            countZ: 0,
            multipleSelection: [],
            getLinkShow: false,
            getLinkUrl: '',
            timer:''
        }
    },
    mounted() {
        if(!localStorage.getItem('DDTtoken')){
            this.$alert('您还未登录，请登录', {
                confirmButtonText: '确定',
                callback:action => {
                    if(action == 'confirm'){
                         this.$router.push('/login')
                    }else {
                        this.$message({
                            type: 'info',
                            message: "取消",
                            center:true,
                            showClose: true,
                        });
                        this.$router.push('/')
                    }
                }
            });
        }else{
            var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
            if(decoded.status == 0){
                this.$alert('您的账户正在审核中，审核通过即可使用电子合同功能', {
                    confirmButtonText: '确定',
                    callback:action => {
                        if(action == 'confirm'){
                            this.$router.push('/')
                        }else {
                            this.$message({
                                type: 'info',
                                message: "取消",
                                center:true,
                                showClose: true,
                            });
                            this.$router.push('/')
                        }
                    }
                });
            }
            // 如果用户注册成功过电子合同则直接显示用户注册信息
            if (decoded.ecid) {
                this.searchUserMsg()
            }
        }
    },
    methods: {
        handleClick(tab) {
            if (tab.name == 'third') {
                this.multipleSelection = []
                this.SignedListA()
            } else if (tab.name == 'fourth') {
                this.multipleSelection = []
                this.SignedListB()
            } else if (tab.name == 'fifth') {
                this.contractSearch()
            } else if (tab.name == 'sixth') {
                this.zfcontractSearch()
            }
        },
        // 实人认证
        verify() { 
            this.$refs.verifyMsg.validate((valid) => {
                if (valid) { 
                    var fd = new FormData()
                    fd.append('oauth_type','person')
                    fd.append('name',this.verifyMsg.name)
                    fd.append('idNo',this.verifyMsg.idNo)
                    fd.append('account',this.verifyMsg.account)
                    fd.append('redirect_url','https://www.dadaex.cn/')
                    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8';
                    axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                    axios.post(this.GLOBAL.BASE_URL1 + '/contract/e_auth', fd)
                    .then(res => {
                        if (res.data.code == 0) {
                            this.verifyShow = false
                            this.iframeUrl = res.data.data
                            this.iframeShow = true
                            this.timer = setInterval(() => {
                                this.poll()
                            }, 2000);
                            setTimeout(()=>{
                                clearInterval(this.timer)
                                if(this.iframeShow == true){
                                    this.$message({
                                        type: 'error',
                                        message: '实人验证未通过',
                                        showClose: true,
                                        center: true
                                    });
                                    this.verifyShow = true
                                    this.iframeShow = false
                                }
                            },2 * 60 * 1000)
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.message,
                                showClose: true,
                                center: true
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                }
            })
        },
        // 实人认证轮询
        poll() { 
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/contract/e_auth', {
                params: {
                    idNo:this.verifyMsg.idNo
                }
            })
            .then(res => {
                if (res.data.code == 200) {
                    clearInterval(this.timer)
                    this.$message({
                        type: 'success',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                    this.iframeShow = false
                    this.registerMsg.user_name = this.verifyMsg.name
                    this.registerMsg.user_id_number = this.verifyMsg.idNo
                    this.registerMsg.user_mobile = this.verifyMsg.account
                    this.registerShow = true
                    this.allowRegister = true
                }
            })
            .catch(error => {
                console.log(error);
            })
        },
        // 电子合同注册
        conRegister() { 
            this.$refs.registerMsg.validate((valid) => {
                if (valid) {
                    var fd = new FormData()
                    fd.append('user_name', this.registerMsg.user_name)
                    fd.append('user_id_type','CRED_PSN_CH_IDCARD')
                    fd.append('user_id_number', this.registerMsg.user_id_number)
                    fd.append('user_mobile', this.registerMsg.user_mobile)
                    fd.append('user_email', this.registerMsg.user_email)
                    fd.append('company_name', this.registerMsg.company_name)
                    fd.append('company_id_type', this.registerMsg.company_id_type)
                    fd.append('company_id_number',this.registerMsg.company_id_number)
                    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8';
                    axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                    axios.post(this.GLOBAL.BASE_URL1 + '/contract/register',fd)
                    .then(res=>{
                        if(res.data.status_code == 0){
                            this.$message({
                                type: 'success',
                                message: res.data.message,
                                showClose: true,
                                center: true
                            });
                            localStorage.setItem('DDTtoken',res.data.new_token)
                            this.searchUserMsg()
                        }else {
                            this.$message({
                                type: 'error',
                                message: res.data.message,
                                showClose: true,
                                center: true
                            });
                        }
                    })
                    .catch(error=>{
                        console.log(error);
                    })
                }
            })
        },
        // 文件状态查询
        fileStatus(){
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/contract/file_status',{
                params:{
                    fileId:'5682237d77654eecb0da473e0d67b3b4'
                }
            })
            .then(res=>{
                console.log(res);
            })
            .catch(error=>{
                console.log(error);
            })
        },
        OnSuccess (file, fileList) {
            this.fileList = fileList
        },
        OnRemove (file, fileList) {
            this.fileList = fileList
        },
        onSubmit () {
            if(!this.businessScene){
                this.$message({
                    type: 'warning',
                    message: '请填写签属合同主题!',
                    showClose: true,
                    center: true
                });
                return
            }
            if(!this.party_b_org_code){
                this.$message({
                    type: 'warning',
                    message: '请填写乙方组织机构代码!',
                    showClose: true,
                    center: true
                });
                return
            }
            if (this.fileList.length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请选择上传文件!',
                    showClose: true,
                    center: true
                });
                return
            }
            const that = this
            for (var i in this.fileList) {
                var file = this.fileList[i]
                var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
                const extension = testmsg === 'pdf'
                if (!extension) {
                  continue
                }
                var fd = new FormData()
                fd.append('file',this.fileList[0].raw)
                fd.append('businessScene',this.businessScene)
                fd.append('party_b_org_code',this.party_b_org_code)
                axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
                axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                axios.post( this.GLOBAL.BASE_URL1 +'/contract/upload',fd )
                .then(res => {
                    if(res.data.errCode == 0){
                        this.businessScene = ''
                        this.party_b_org_code = ''
                        this.$message({
                            type: 'success',
                            message: res.data.msg,
                            showClose: true,
                            center: true
                        });
                    }else {
                        this.$message({
                            type: 'error',
                            message: res.data.msg,
                            showClose: true,
                            center: true
                        }); 
                    }
                })
                .catch(error=>{
                    console.log(error);
                })
            }
            that.fileList = []
        },
        onExceed(){
            this.$message({
                type: 'warning',
                message: '每次只能上传一个文件!',
                showClose: true,
                center: true
            });
        },
        selectChanged (value) {
            console.log(value);
        },
        httpRequest (options) {
            console.log(options);
        },
        // 合同签属列表（甲方）
        SignedListA(){
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/contract/PersonalFilesLaunch',{
                params:{
                    page: this.pageA,
                    per_page: this.per_pageA
                }
            })
            .then(res=>{
                if(res.data.status_code == 0){
                    this.contractListA = res.data.data
                    this.countA = res.data.count
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 甲方分页
        handleSizeChangeA(val) {
            this.per_pageA = val
            this.SignedListA()
        },
        handleCurrentChangeA(val) {
            this.pageA = val
            this.SignedListA()
        },
        // 勾选
        selectContractList(val) { 
            this.multipleSelection = val
        },
        // 合同签属列表（乙方）
        SignedListB(){
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/contract/PersonalFilesSign',{
                params:{
                    page: this.pageB,
                    per_page: this.per_pageB
                }
            })
            .then(res=>{
                if(res.data.status_code == 0){
                    this.contractListB = res.data.data
                    this.countB = res.data.count
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        }, 
        // 乙方分页
        handleSizeChangeB(val) {
            this.per_pageB = val
            this.SignedListB()
        },
        handleCurrentChangeB(val) {
            this.pageB = val
            this.SignedListB()
        },
        //  预览下载
        download() {
            if (this.multipleSelection.length != 1) {
                this.$message({
                    type: 'warning',
                    message: '请勾选一条数据进行操作',
                    showClose: true,
                    center: true
                });
            } else {
                if(this.multipleSelection[0].flowId == null){
                    this.downloadNotsigned(this.multipleSelection[0].fileId)
                }else {
                    this.downloadSigned(this.multipleSelection[0].flowId)
                }
            }
        },
        // 合同签属-一键下载 ----已签署
        downloadSigned(val){
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/contract/gateway_sign_flows/'+ val,{
                params:{

                }
            })
            .then(res=>{
                if(res.data.code == 0){
                    window.open(res.data.data.docs[0].fileUrl)
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 一键下载 --- 未签署
        downloadNotsigned(val){
            // console.log('未签署合同');
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/contract/file_status/'+ val,{
                params:{

                }
            })
            .then(res=>{
                if(res.data.code == 0){
                    window.open(res.data.data.downloadUrl)
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 一键签属
        signed(type) {
            if (this.multipleSelection.length != 1) {
                this.$message({
                    type: 'warning',
                    message: '请勾选一条数据进行操作',
                    showClose: true,
                    center: true
                });
            } else { 
                var fd = new FormData()
                fd.append('fileId',this.multipleSelection[0].fileId)
                axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8';
                axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                axios.post(this.GLOBAL.BASE_URL1 + '/contract/CreateFlowOneStep',fd)
                .then(res=>{
                    if(res.data.code == 0){
                        this.$message({
                            type: 'success',
                            message: res.data.message,
                            showClose: true,
                            center: true
                        });
                        if (type == 'A') {
                            this.SignedListA()
                        } else if (type == 'B') {
                            this.SignedListB()
                        }
                    }else {
                        this.$message({
                            type: 'error',
                            message: res.data.message,
                            showClose: true,
                            center: true
                        });
                    }
                })
                .catch(error=>{
                    console.log(error);
                })
            }
            
        },
        // 催签
        Rush() { 
            if (this.multipleSelection.length != 1) {
                this.$message({
                    type: 'warning',
                    message: '请勾选一条数据进行操作',
                    showClose: true,
                    center: true
                });
            } else if (!this.multipleSelection[0].flowId) { 
                this.$message({
                    type: 'warning',
                    message: '合同未签属,不能催签',
                    showClose: true,
                    center: true
                });
            } else {
                axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                axios.put(this.GLOBAL.BASE_URL1 + '/contract/gateway_sign_flows/'+ this.multipleSelection[0].flowId,{
                    params:{}
                })
                .then(res=>{
                    if(res.data.code == 0){
                        this.$message({
                            type: 'success',
                            message: '已催签',
                            showClose: true,
                            center: true
                        });
                    }else {
                        this.$message({
                            type: 'error',
                            message: res.data.message,
                            showClose: true,
                            center: true
                        });
                    }
                })
                .catch(error=>{
                    console.log(error);
                })
            }
        },
        // 获取签属链接
        getLink() { 
            if (this.multipleSelection.length != 1) {
                this.$message({
                    type: 'warning',
                    message: '请勾选一条数据进行操作',
                    showClose: true,
                    center: true
                });
            } else {
                var fd = new FormData()
                fd.append('flowId',this.multipleSelection[0].flowId)
                axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8';
                axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                axios.post(this.GLOBAL.BASE_URL1 + '/contract/gateway_sign_flows',fd)
                .then(res=>{
                    if(res.data.code == 0){
                        const { shortUrl } = res.data.data
                        this.getLinkShow = true
                        this.getLinkUrl = shortUrl
                    }else {
                        this.$message({
                            type: 'error',
                            message: res.data.message,
                            showClose: true,
                            center: true
                        });
                    }
                })
                .catch(error=>{
                    console.log(error);
                })
            }
        },
        // 已签署合同列表
        contractSearch(){
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/contract/PersonalFilesComplete',{
                params:{
                    page: this.pageL,
                    per_page: this.per_pageL
                }
            })
            .then(res=>{
                if(res.data.status_code == 0){
                    this.contractLists = res.data.data
                    this.countL = res.data.count
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 分页
        handleSizeChangeL(val) {
            this.per_pageL = val
            this.contractSearch()
        },
        handleCurrentChangeL(val) {
            this.pageL = val
            this.contractSearch()
        },
        // 查询电子合同用户信息
        searchUserMsg(){
            var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/contract/SignUser',{
                params:{
                    ecid: decoded.ecid
                }
            })
            .then(res=>{
                if(res.data.status_code == 0){
                    this.verifyShow = false
                    this.registerShow = true
                    this.allowRegister = false
                    const {per_name, per_idNumber,per_mobile, per_email,org_name,org_idType,org_idnumber } = res.data.data
                    this.registerMsg.user_name = per_name
                    this.registerMsg.user_id_number = per_idNumber
                    this.registerMsg.user_mobile = per_mobile
                    this.registerMsg.user_email = per_email
                    this.registerMsg.company_name = org_name
                    this.registerMsg.company_id_type = org_idType
                    this.registerMsg.company_id_number = org_idnumber
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
        },
        // 合同作废
        cancellation(type) {
            // delete:甲方删除，未发起签属流程
            // cancel：甲方或乙方一方已签署，作废
            if (this.multipleSelection.length != 1) {
                this.$message({
                    type: 'warning',
                    message: '请勾选一条数据进行操作',
                    showClose: true,
                    center: true
                });
            } else {
                if (type == 'delete' && this.multipleSelection[0].flowId) {
                    this.$message({
                        type: 'warning',
                        message: '签属流程已发起，不能进行删除',
                        showClose: true,
                        center: true
                    });
                } else if (type == 'delete' && !this.multipleSelection[0].flowId) {
                    // 删除
                    this.cancellationFun(type)
                } else if (type == 'cancel' && !this.multipleSelection[0].flowId) {
                    this.$message({
                        type: 'warning',
                        message: '签属流程未开启，不能进行作废',
                        showClose: true,
                        center: true
                    });
                } else if (type == 'cancel' && this.multipleSelection[0].flowId) {
                    // 作废
                    this.cancellationFun(type)
                }
            }
        },
        cancellationFun(type) { 
                var fd = new FormData()
                fd.append('fileId',this.multipleSelection[0].fileId)
                fd.append('cancel', 1)
                fd.append('function_type',type)
                axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8';
                axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                axios.post(this.GLOBAL.BASE_URL1 + '/contract/ContractCancel',fd)
                .then(res=>{
                    if(res.data.status_code == 0){
                        this.$message({
                            type: 'success',
                            message: res.data.message,
                            showClose: true,
                            center: true
                        });
                        this.SignedListA()
                    }else {
                        this.$message({
                            type: 'error',
                            message: res.data.message,
                            showClose: true,
                            center: true
                        });
                    }
                })
                .catch(error=>{
                    console.log(error);
                })
        },
        // 作废合同分页
        // 分页
        handleSizeChangeZ(val) {
            this.per_pageZ = val
            this.zfcontractSearch()
        },
        handleCurrentChangeZ(val) {
            this.pageZ = val
            this.zfcontractSearch()
        },
        // 作废合同列表
        zfcontractSearch(){
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/contract/PersonalFilesCancel',{
                params:{
                    page: this.pageZ,
                    per_page: this.per_pageZ
                }
            })
            .then(res=>{
                if(res.data.status_code == 0){
                    this.contractListZ = res.data.data
                    this.countZ= res.data.count
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        copy (data) {
            var _input = document.createElement("input");   // 直接构建input
            _input.value = data;  // 设置内容
            document.body.appendChild(_input);    // 添加临时实例
            _input.select();   // 选择实例内容
            document.execCommand("Copy");   // 执行复制
            this.$message({
                type: 'success',
                message: '复制成功!',
                showClose: true,
                center: true
            });
            document.body.removeChild(_input); // 删除临时实例
            this.getLinkShow = false
        }
    },
    // sockets: {
    //     // 链接成功
    //     connect(data) {
    //         console.log('链接成功',data)
    //     },
    //     // 发送消息
    //     toServer(data) {
    //       this.$socket.emit('e_sign', data)
    //     },
    //     // 接收消息
    //     e_sign(data) {
    //         const { username, user_id_num, mobile } = data.data
    //         if (data.code == 200 && this.verifyMsg.name == username && this.verifyMsg.idNo == user_id_num && this.verifyMsg.account == mobile) { 
    //             this.$message({
    //                 type: 'success',
    //                 message: data.message,
    //                 showClose: true,
    //                 center: true
    //             });
    //             this.iframeShow = false
    //             this.registerMsg.user_name = username
    //             this.registerMsg.user_id_number = user_id_num
    //             this.registerMsg.user_mobile = mobile
    //             this.registerShow = true
    //             this.allowRegister = true
    //         }
    //     },
    //     // 断开链接回调
    //     disconnect() {
    //       console.log('disconnect:', '已经断开 socket 链接')
    //     },
    //     // 重新连接
    //     reconnect() {
    //       // 自动执行，直到链接成功
    //       this.$socket.emit('e_sign')
    //     }
    //   }
}