<template>
  <div class="Contract">
      <!-- 电子合同 -->
      <div class="contract-inner">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="电子合同注册" name="first">
                 <div class="contract-register">
                    <div class="cont-reg-tit">
                        <h2>电子合同注册认证</h2>
                    </div>
                    <div class="cont-reg-main" v-show="verifyShow">
                        <el-form :model="verifyMsg" :rules="rules" ref="verifyMsg" label-width="100px">
                            <el-form-item label="姓名" prop="name">
                                <el-input v-model="verifyMsg.name"></el-input>
                            </el-form-item>
                            <el-form-item label="身份证号" prop="idNo">
                                <el-input v-model="verifyMsg.idNo"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号" prop="account">
                                <el-input v-model="verifyMsg.account"></el-input>
                            </el-form-item>
                            <div class="cont-teg-reg">
                                <button type="button" @click="verify()">认证</button>
                            </div>
                        </el-form>
                    </div>
                    <div class="cont-reg-main" v-if="iframeShow">
                        <div class="ifr">
                            <iframe width=1200 height=600 frameborder=0 scrolling=yes :src='iframeUrl'></iframe>
                        </div>
                    </div>
                     <div class="cont-reg-main" v-show="registerShow">
                          <el-form :model="registerMsg" :rules="registerRules" ref="registerMsg" label-width="120px">
                            <el-form-item label="姓名" prop="user_name">
                                <el-input v-model="registerMsg.user_name"></el-input>
                            </el-form-item>
                            <el-form-item label="身份证号" prop="user_id_number">
                                <el-input v-model="registerMsg.user_id_number"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号" prop="user_mobile">
                                <el-input v-model="registerMsg.user_mobile"></el-input>
                            </el-form-item>
                            <el-form-item label="邮箱" prop="user_email">
                                <el-input v-model="registerMsg.user_email"></el-input>
                            </el-form-item>
                            <el-form-item label="机构名称" prop="company_name">
                                <el-input v-model="registerMsg.company_name"></el-input>
                            </el-form-item>
                            <el-form-item label="机构类型" prop="company_id_type">
                               <el-select v-model="registerMsg.company_id_type" placeholder="请选择">
                                    <el-option
                                        v-for="item in company_id_type_list"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="组织机构代码" prop="company_id_number">
                                <el-input v-model="registerMsg.company_id_number"></el-input>
                            </el-form-item>
                            <div class="cont-teg-reg" v-if="allowRegister">
                                <button type="button" @click="conRegister()">注册</button>
                            </div>
                        </el-form>
                     </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="合同上传" name="second">
                <div class="contract-upload">
                    <div class="cont-up-main">
                         <el-form ref="form" id="uploadFile" :model="form">
                             <el-form-item>
                                <div class="location">
                                    <el-row>
                                        <el-col :span="5">
                                            <label for="">签属合同主题<span>*</span></label>
                                        </el-col>
                                        <el-col :span="19">
                                            <el-input v-model="businessScene" placeholder="请填写签属合同主题，例如：“xxx公司劳动合同书”"></el-input>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="5">
                                            <label for="">乙方组织机构代码<span>*</span></label>
                                        </el-col>
                                        <el-col :span="19">
                                            <el-input v-model="party_b_org_code" :input="party_b_org_code = party_b_org_code.replace(/\s+/g,'')" placeholder="请填写乙方组织机构代码"></el-input>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-form-item>
                            <el-row>
                                <el-col>
                                    <el-form-item>
                                        <el-upload
                                        class="upload-demo"
                                        drag
                                        ref="upload"
                                        action="#"
                                        :auto-upload="false"
                                        :on-change="OnSuccess"
                                        :on-remove="OnRemove"
                                        :on-exceed="onExceed"
                                        :http-request="httpRequest"
                                        :file-list="fileList"
                                        accept=".pdf"
                                        :limit="1"
                                        name="file"
                                        >
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                        <div class="el-upload__tip" slot="tip">每次只能上传一个 pdf 文件,且大小不能超过20M</div>
                                        </el-upload>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="warning" :disabled="isAble" @click="onSubmit">立即上传</el-button>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="需要他人签属" name="third">
                <div class="signing">
                    <div class="signing-tit">
                        <h2>合同签署</h2>
                    </div>
                    <div class="signing-main">
                        <div class="signing-btn">
                            <el-button type="success" @click="download()">预览下载</el-button>
                            <el-button type="primary" @click="signed('A')">一键签署</el-button>
                            <el-button type="warning" @click="Rush()">催签</el-button>
                            <el-button type="success" @click="getLink()">获取签属链接</el-button>
                            <el-button type="info" @click="cancellation('cancel')">作废</el-button>
                            <el-button type="danger" @click="cancellation('delete')">删除</el-button>
                        </div>
                        <el-table
                            :header-cell-style="{background:'#ee9e58',color:'#fff'}"
                            :data="contractListA"
                            style="width: 100%"
                            @selection-change="selectContractList">
                            <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column>
                            <el-table-column
                            label="主题"
                            show-overflow-tooltip
                            >
                            <template slot-scope="scope">
                                <span>{{scope.row.file_theme}}</span>
                            </template>
                            </el-table-column>
                            <el-table-column
                            label="文件名称"
                            show-overflow-tooltip
                            >
                            <template slot-scope="scope">
                                <span>{{scope.row.file_name}}</span>
                            </template>
                            </el-table-column>
                            <el-table-column
                                label="发起方公司名"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.party_a_org_name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="发起方签属状态"
                            >
                            <template slot-scope="scope">
                                {{scope.row.party_a_sign_state == 2 ? '签署完成' : scope.row.party_a_sign_state == 3 ? '失败' : scope.row.party_a_sign_state == 4 ? '拒签' : scope.row.party_a_sign_state}}
                            </template>
                            </el-table-column>
                            <el-table-column
                                label="签属方公司名"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.party_b_org_name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="签署方签属状态"
                                >
                                <template slot-scope="scope">
                                {{scope.row.party_a_sign_state == 2 ? '签署完成' : scope.row.party_a_sign_state == 3 ? '失败' : scope.row.party_a_sign_state == 4 ? '拒签' : scope.row.party_a_sign_state}}
                            </template>
                            </el-table-column>
                            <el-table-column
                                prop="sign_time"
                                width="150"
                                label="签属方签属时间"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="create_time"
                                label="创建时间"
                                width="150"
                                >
                            </el-table-column>
                        </el-table>
                        <div class="page" v-if="contractListA.length != 0">
                             <el-pagination
                                @size-change="handleSizeChangeA"
                                @current-change="handleCurrentChangeA"
                                :current-page="currentPageA"
                                :page-sizes="[10, 20, 30, 40, 50, 100]"
                                :page-size="10"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="countA">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="需要我签属" name="fourth">
                <div class="signing">
                    <div class="signing-tit">
                        <h2>合同签署</h2>
                    </div>
                    <div class="signing-main">
                        <div class="signing-btn">
                            <el-button type="success" @click="download()">预览下载</el-button>
                            <el-button type="primary" @click="signed('B')">一键签署</el-button>
                            <el-button type="warning" @click="Rush()">催签</el-button>
                            <el-button type="success" @click="getLink()">获取签属链接</el-button>
                            <el-button type="info" @click="cancellation('cancel')">作废</el-button>
                        </div>
                        <el-table
                            :header-cell-style="{background:'#ee9e58',color:'#fff'}"
                            :data="contractListB"
                            style="width: 100%"
                            @selection-change="selectContractList">
                            <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column>
                            <el-table-column
                            label="主题"
                            show-overflow-tooltip
                            >
                            <template slot-scope="scope">
                                <span>{{scope.row.file_theme}}</span>
                            </template>
                            </el-table-column>
                            <el-table-column
                            label="文件名称"
                            show-overflow-tooltip
                            >
                            <template slot-scope="scope">
                                <span>{{scope.row.file_name}}</span>
                            </template>
                            </el-table-column>
                            <el-table-column
                                label="发起方公司名"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.party_a_org_name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="发起方签属状态"
                            >
                            <template slot-scope="scope">
                                {{scope.row.party_a_sign_state == 2 ? '签署完成' : scope.row.party_a_sign_state == 3 ? '失败' : scope.row.party_a_sign_state == 4 ? '拒签' : scope.row.party_a_sign_state}}
                            </template>
                            </el-table-column>
                            <el-table-column
                                label="签属方公司名"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.party_b_org_name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="签署方签属状态"
                                >
                                <template slot-scope="scope">
                                {{scope.row.party_a_sign_state == 2 ? '签署完成' : scope.row.party_a_sign_state == 3 ? '失败' : scope.row.party_a_sign_state == 4 ? '拒签' : scope.row.party_a_sign_state}}
                            </template>
                            </el-table-column>
                            <el-table-column
                                prop="sign_time"
                                width="150"
                                label="签属方签属时间"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="create_time"
                                label="创建时间"
                                width="150"
                                >
                            </el-table-column>
                        </el-table>
                        <div class="page" v-if="contractListB.length != 0">
                             <el-pagination
                                @size-change="handleSizeChangeB"
                                @current-change="handleCurrentChangeB"
                                :current-page="currentPageA"
                                :page-sizes="[10, 20, 30, 40, 50, 100]"
                                :page-size="10"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="countB">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="已签署合同列表" name="fifth">
                <div class="ContractSearch">
                     <div class="signing-tit">
                        <h2>合同列表</h2>
                    </div>
                    <div class="con-search-main">
                         <el-table
                            :header-cell-style="{background:'#ee9e58',color:'#fff'}"
                            :data="contractLists"
                            style="width: 100%">
                            <el-table-column
                            label="文件名称"
                            show-overflow-tooltip
                            >
                            <template slot-scope="scope">
                                <span>{{scope.row.file_name}}</span>
                            </template>
                            </el-table-column>
                            <el-table-column
                                label="发起方公司名"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.party_a_org_name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="发起方签属状态"
                            >
                            <template slot-scope="scope">
                                {{scope.row.party_a_sign_state == 2 ? '签署完成' : scope.row.party_a_sign_state == 3 ? '失败' : scope.row.party_a_sign_state == 4 ? '拒签' : scope.row.party_a_sign_state}}
                            </template>
                            </el-table-column>
                            <el-table-column
                                label="签属方公司名"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.party_b_org_name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="签署方签属状态"
                                >
                                <template slot-scope="scope">
                                {{scope.row.party_a_sign_state == 2 ? '签署完成' : scope.row.party_a_sign_state == 3 ? '失败' : scope.row.party_a_sign_state == 4 ? '拒签' : scope.row.party_a_sign_state}}
                            </template>
                            </el-table-column>
                            <el-table-column
                                prop="sign_time"
                                width="150"
                                label="签属方签属时间"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="create_time"
                                label="创建时间"
                                width="150"
                                >
                            </el-table-column>
                            <el-table-column
                            label="操作"
                            width="80">
                            <template slot-scope="scope">
                                <el-button type="warning" size="small" @click="downloadSigned(scope.row.flowId)"><span class="el-icon-download" ></span></el-button>
                            </template>
                            </el-table-column>
                        </el-table>
                        <div class="page" v-if="contractLists.length != 0">
                                <el-pagination
                                    @size-change="handleSizeChangeL"
                                    @current-change="handleCurrentChangeL"
                                    :current-page="currentPageL"
                                    :page-sizes="[10, 20, 30, 40, 50, 100]"
                                    :page-size="10"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="countL">
                                </el-pagination>
                        </div>
                    </div>
                 </div>
            </el-tab-pane>
             <el-tab-pane label="作废合同列表" name="sixth">
                 <div class="ContractSearch">
                     <div class="signing-tit">
                        <h2>作废合同列表</h2>
                    </div>
                    <div class="con-search-main">
                         <el-table
                            :header-cell-style="{background:'#ee9e58',color:'#fff'}"
                            :data="contractListZ"
                            style="width: 100%">
                            <el-table-column
                            label="文件名称"
                            show-overflow-tooltip
                            >
                            <template slot-scope="scope">
                                <span>{{scope.row.file_name}}</span>
                            </template>
                            </el-table-column>
                            <el-table-column
                                label="发起方公司名"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.party_a_org_name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="发起方签属状态"
                            >
                            <template slot-scope="scope">
                                {{scope.row.party_a_sign_state == 2 ? '签署完成' : scope.row.party_a_sign_state == 3 ? '失败' : scope.row.party_a_sign_state == 4 ? '拒签' : scope.row.party_a_sign_state}}
                            </template>
                            </el-table-column>
                            <el-table-column
                                label="签属方公司名"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.party_b_org_name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="签署方签属状态"
                                >
                                <template slot-scope="scope">
                                {{scope.row.party_a_sign_state == 2 ? '签署完成' : scope.row.party_a_sign_state == 3 ? '失败' : scope.row.party_a_sign_state == 4 ? '拒签' : scope.row.party_a_sign_state}}
                            </template>
                            </el-table-column>
                            <el-table-column
                                prop="sign_time"
                                width="150"
                                label="签属方签属时间"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="create_time"
                                label="创建时间"
                                width="150"
                                >
                            </el-table-column>
                        </el-table>
                        <div class="page" v-if="contractListZ.length != 0">
                            <el-pagination
                                @size-change="handleSizeChangeZ"
                                @current-change="handleCurrentChangeZ"
                                :current-page="currentPageZ"
                                :page-sizes="[10, 20, 30, 40, 50, 100]"
                                :page-size="10"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="countZ">
                            </el-pagination>
                        </div>
                    </div>
                 </div>
             </el-tab-pane>
        </el-tabs>
        <el-dialog
            title="签署链接"
            :visible.sync="getLinkShow"
            v-if="getLinkShow"
            width="30%"
            center>
            <div class="getlinkdiv">
                <p>{{getLinkUrl}}</p>
                <p>
                    <el-button size="small" type="warning" @click="copy(getLinkUrl)">一键复制</el-button>
                </p>
            </div>
        </el-dialog>
      </div>
  </div>
</template>

<script>
    import Contract from './Contract.js'
    export default Contract;
</script>

<style>
    @import './Contract.css';
</style>